$background: #E0E0E0;
$black: #212121;
$darkGrey: #4e4e4e;
$lightGrey: #aeaeae;
$white: #ffffff;
$offWhite: #f1f1f1;
$accent: #00BCD4;
$dark: #0D47A1;
$box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1);
$underline-avoider: 0.03em 0 $background, -0.03em 0 $background, 0 0.03em $background, 0 -0.03em $background, 0.06em 0 $background, -0.06em 0 $background, 0.09em 0 $background, -0.09em 0 $background, 0.12em 0 $background, -0.12em 0 $background, 0.15em 0 $background, -0.15em 0 $background;
$sans-serif: "Helvetica Neue", "Calibri Light", Roboto, sans-serif;
$title-font: 'Lato', sans-serif;
$container: 550px;
