@import './variables';
@import './mixins';
@import './base';

header {
    @include panel();
    @include shard(top);
    background: $background;
    z-index: 1;

    &:after {
        animation-name           : reveal;
        animation-duration       : 0.8s;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;
        z-index: 10;
    }

    ul {
        padding: 0.2rem;
    }

    li {
        list-style-type: none;
        margin: 0.5em 0;
    }

    article {
        width       : 95%;
        max-width   : $container;
        @media(min-width: 500px) {
          border-right: 1px solid $lightGrey;
        }

        p {
            text-align: left;
            padding   : 0 0.1em;
            box-sizing: border-box;
        }

        a {
            text-shadow    : $underline-avoider;
            text-decoration: none;
            z-index        : 1;
            user-select    : none;

            &:before {
                content   : ' ';
                position  : absolute;
                display   : inline-block;
                bottom    : 0;
                left      : 0.5%;
                width     : 99%;
                height    : 1px;
                background: $accent;
                z-index   : -1;
            }
        }

        &:before {
            content                  : '';
            position                 : absolute;
            right                    : 0;
            top                      : 0;
            height                   : 100%;
            width                    : 1px;
            color                    : $lightGrey;
            animation-name           : slideDown;
            animation-duration       : 2.5s;
            animation-iteration-count: 1;
        }
    }
}

main {
    @include panel();
    background: $dark;

    article {
        width    : 95%;
        max-width: $container;
    }

    h2 {
        color    : darken($dark, 10);
        font-size: 3em;
        margin   : 0;
        padding  : 0;
    }

    p {
        color      : $white;
        margin-top : 0.2em;
        padding-top: 0;
    }
}

footer {
    @include panel();
    background: $background;

    article {
        width    : 95%;
        max-width: $container;
    }

    h2 {
        text-align: center;
        font-size : 2.5em;
        margin    : 0;
    }

    ul {
        padding   : 0;
        width     : 100%;
        overflow  : hidden;
        box-sizing: border-box;
        border-left: 1px solid $accent;
    }

    li {
        display   : block;
        margin    : 4.7em auto;
        width     : 100%;
        list-style: none;
        text-align: center;

        &:nth-child(even) {
            a {
                &:before {
                    transform: rotate(-10deg);
                }
            }
        }
    }

    a {
        display  : block;
        width    : 100%;
        position : relative;
        font-size: 1.1em;
        z-index  : 1;

        &:before {
            content   : ' ';
            position  : absolute;
            top       : -72%;
            width     : 200%;
            height    : 250%;
            left      : -50%;
            background: transparentize($lightGrey, 0.5);
            z-index   : -1;
            transform : rotate(10deg);
            transition: background 0.4s ease;
        }

        &:hover {
            &:before {
                background: $lightGrey;
            }
        }
    }
}

.text-center {
    text-align: center;
}

.button {
    display: inline-block;
    background-color: $dark;
    color: $white;
    border: 1px solid $white;
    padding: 0.2rem 1.8rem 0.3rem;
    border-radius: 0.3rem;
    margin: 0.6rem 0;
    transition: all 0.2s ease;

    &:hover {
        background-color: $white;
        color: $dark;
    }
}