
body,
html {
    padding               : 0;
    margin                : 0;
    background            : $white;
    color                 : $black;
    font-family           : $sans-serif;
    overflow-x            : hidden;
    -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h5 {
    margin     : 0;
    font-family: $title-font;
    font-weight: normal;
}

h1 {
    font-size: 1.8em;
    @media(min-width: 600px) {
      font-size: 2em;
    }
}

p {
    padding    : 0 0.1em;
    box-sizing : border-box;
    text-align : justify;
    line-height: 1.7em;
    color      : $darkGrey;
    user-select: none;
}

a {
    position                   : relative;
    color                      : $darkGrey;
    text-decoration            : none;
    z-index                    : 1;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
}
